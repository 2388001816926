;(function () {
    'use strict';

    var tippyHideOnEsc = {
        name: 'hideOnEsc',
        defaultValue: true,
        fn({hide}) {
            function onKeyDown(event) {
                if (event.keyCode === 27) {
                    hide();
                }
            }
            return {
                onShow() {
                    document.addEventListener('keydown', onKeyDown);
                },
                onHide() {
                    document.removeEventListener('keydown', onKeyDown);
                },
            };
        },
    };

    var tippyHideOnPopperBlur = {
        name: 'hideOnPopperBlur',
        defaultValue: true,
        fn(instance) {
            return {
                onCreate() {
                    instance.popper.addEventListener('focusout', (event) => {
                        if (
                            instance.props.hideOnPopperBlur &&
                            event.relatedTarget &&
                            !instance.popper.contains(event.relatedTarget)
                        ) {
                            instance.hide();
                        }
                    });
                },
            };
        },
    };

    tippy('.js-tippy', {
        allowHTML: false,
        animation: 'scale-subtle',
        aria: {content: 'describedby'},
        arrow: true,
        delay: 40,
        hideOnClick: true,
        inlinePositioning: true,
        interactive: true,
        interactiveBorder: 30,
        maxWidth: '17.5rem',
        role: 'tooltip',
        trigger: 'mouseenter focus focusin',
        // trigger: 'click',
        zIndex: 10,
        plugins: [tippyHideOnEsc, tippyHideOnPopperBlur],
        theme: 'light-border'
    });
}());
