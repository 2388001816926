;(function () {
    'use strict';

    var toolbarToggle = document.querySelector('.js-page-toolbar-nav-toggle');
    if (toolbarToggle)
    {
        onClickOrTap(toolbarToggle, function(e) {
            e.preventDefault();
            var viewportWidth = window.innerWidth;
            if (viewportWidth < 768) {
                var dialog = getActiveA11yDialog('modal-report');
                if (!dialog) {
                    dialog = getActiveA11yDialog('modal-topic');
                }
                if (!dialog) {
                    dialog = getActiveA11yDialog('modal-fallback');
                }
                if (dialog) {
                    dialog.show();
                }
            }
            else {
                var nav = document.querySelector('.js-site-nav');
                if (nav) {
                    var isOpen = nav.classList.contains('is-open');
                    if (!isOpen) {
                        nav.style.display = 'block';
                    }
                    setTimeout(function() {
                        doTriggerToggle(toolbarToggle);
                    }, 20);
                    setTimeout(function() {
                        if (isOpen) nav.style.display = '';
                    }, 500);
                }
            }
        });
    }
}());
