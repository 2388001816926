var doTriggerToggle = function(trigger)
{
    var toggleName = trigger.getAttribute('data-toggle-target');
    var toggle = null;
    if (toggleName !== null) {
        toggle = document.querySelector('.js-toggle[data-toggle-name="' + toggleName + '"]');
    }
    if (toggle === null) {
        toggle = getClosest(trigger, '.js-toggle');
    }
    var doCloseChildren = trigger.getAttribute('data-toggle-close-children');
    doCloseChildren = (doCloseChildren !== 'false');
    if (toggle !== null) {
        var isOpen = toggle.classList.contains('is-open');
        isOpen ? doCloseToggle(toggle, trigger, doCloseChildren) : doOpenToggle(toggle, trigger);
    }
};

var doOpenToggle = function(toggle, trigger = null)
{
    var isOpen = toggle.classList.contains('is-open');
    if (isOpen) return;
    var toggleGroupName = toggle.getAttribute('data-toggle-group');
    if (toggleGroupName !== null) {
        var toggleGroup = getClosest(toggle, '.js-toggle-group[data-toggle-group="' + toggleGroupName + '"]');
        if (toggleGroup !== null) {
            doCloseToggleGroup(toggleGroup);
        }
    }
    toggle.classList.add('is-open');
    trigger = trigger === null ? toggle.querySelector('.js-toggle-trigger') : trigger;
    trigger.setAttribute('aria-expanded', 'true');
    var toggleBody = toggle.querySelector('.js-toggle-body');
    if (toggleBody !== null) {
        addToggleOverlay(toggle);
    }
};

var addToggleOverlay = function(toggle)
{
    var toggleOverlay = document.createElement('div');
    toggleOverlay.classList.add('js-toggle-overlay');
    var toggleOverlayClass = toggle.getAttribute('data-toggle-overlay-class');
    if (toggleOverlayClass !== null) {
        toggleOverlay.classList.add(toggleOverlayClass);
    }
    toggle.style.zIndex = 9999999;
    toggleOverlay.style.zIndex = 9999998;
    toggleOverlay.style.position = 'fixed';
    toggleOverlay.style.top = 0;
    toggleOverlay.style.left = 0;
    toggleOverlay.style.right = 0;
    toggleOverlay.style.bottom = 0;
    toggle.parentNode.insertBefore(toggleOverlay, toggle);
    doLockToggleFocus(toggle);
    onClickOrTap(toggleOverlay, function(e) {
        doCloseToggle(toggle);
    });
}

var removeToggleOverlay = function(toggle)
{
    var toggleOverlay = toggle.previousElementSibling;
    if (toggleOverlay !== null && toggleOverlay.classList.contains('js-toggle-overlay')) {
        doUnlockToggleFocus(toggle);
        toggleOverlay.parentNode.removeChild(toggleOverlay);
    }
}

var doLockToggleFocus = function(toggle)
{
    focusLock.on(toggle);
    var toggleBody = toggle.querySelector('.js-toggle-body');
    if (toggleBody !== null) {
        var focusableEl = toggleBody.querySelector('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
        if (focusableEl !== null) {
            focusableEl.focus();
            return;
        }
    }
    var toggleTrigger = toggle.querySelector('.js-toggle-trigger');
    if (toggleTrigger !== null) {
        toggleTrigger.focus();
    }
}

var doUnlockToggleFocus = function(toggle)
{
    focusLock.off(toggle);
    var toggleTrigger = toggle.querySelector('.js-toggle-trigger');
    if (toggleTrigger !== null) {
        toggleTrigger.focus();
    }
}

var doCloseToggleGroup = function(toggleGroup)
{
    var toggleGroupName = toggleGroup.getAttribute('data-toggle-group');
    if (toggleGroupName === null) return;
    var toggleGroupAttr = '[data-toggle-group="' + toggleGroupName + '"]';
    var openGroupToggles = toggleGroup.querySelectorAll('.js-toggle.is-open' + toggleGroupAttr);
    Array.prototype.forEach.call(openGroupToggles, function(openGroupToggle, i) {
        doCloseToggle(openGroupToggle);
    });
};

var doCloseToggle = function(toggle, trigger = null, doCloseChildren = true)
{
    removeToggleOverlay(toggle);
    var isOpen = toggle.classList.contains('is-open');
    if (!isOpen) return;
    toggle.classList.remove('is-open');
    trigger = trigger === null ? toggle.querySelector('.js-toggle-trigger') : trigger;
    trigger.setAttribute('aria-expanded', 'false');
    if (doCloseChildren) {
        var childToggles = toggle.querySelectorAll('.js-toggle.is-open');
        Array.prototype.forEach.call(childToggles, function(childToggle, i) {
            doCloseToggle(childToggle, null, false);
        });
    }
};

;(function () {
    'use strict';

    var triggers = document.querySelectorAll('.js-toggle-trigger');
    Array.prototype.forEach.call(triggers, function(trigger, i) {
        onClickOrTap(trigger, function(e) {
            e.preventDefault();
            doTriggerToggle(trigger)
        });
    });

}());
