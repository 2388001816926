;(function () {
    'use strict';

    // 2. Other tables - determine needed flag or configuration to work everywhere
    //    a) Table doesn't fill element, I think we should force container width
    //    b) Long categories now == overflow, col width, or constrain to container width
    //    --> so the CMS flag is "Overflow max container width"
    //    --> need default widths or min-widths on columns else fitColumns won't kick in the scroll
    //    --> maybe only fitDataTable, and column controls to make this produce sensible
    // 3. Design/layout
    // 4. PDF configuration
    // 2. Naming things

    window.activeTabulators = [];

    var tabulatorActions = {
        csv: {
            callback: function(tabulator) {
                tabulator.download('csv', 'data.csv');
            }
        },
        xlsx: {
            callback: function(tabulator) {
                tabulator.download('xlsx', 'data.xlsx', {sheetName: 'PILNA Report'});
            }
        }
    };

    var tabulatorPercentBarFormatter = function(cell, formatterParams, onRendered)
    {
        var row = cell.getRow();

        var text = '<div class="TablePercentBar__text"><span class="TablePercentBar__percent">' + cell.getValue() + '</span>';
        cell.getElement().classList.add('TablePercentBar');

        var seFieldName = formatterParams['se_field'];
        if (seFieldName) {
            var seValue = row.getCell(seFieldName).getValue();
            if (seValue !== '0' && seValue !== 0) {
                text = text + '<span class="TablePercentBar__se">' + seValue + '</span></div>'
            }
        }
        else {
            cell.getElement().classList.add('-se-off');
            text = text + '</div>';
        }

        var doShowBar = formatterParams['do_show_bar'];
        if (doShowBar)
        {
            var bar = '<div class="TablePercentBar__bar"><div class="TablePercentBar__value" style="width:' + cell.getValue() + '%;"></div></div>';
            return '<div class="TablePercentBar__cell"><div class="TablePercentBar__vis">' + bar + text + '</div></div>';
        }
        return text;
    };

    var tabulatorDistributionBarFormatter = function(cell, formatterParams, onRendered)
    {
        var row = cell.getRow();

        var text = '<div class="TablePercentBar__text"><span class="TablePercentBar__percent">' + cell.getValue() + '</span>';
        cell.getElement().classList.add('TablePercentBar');

        var levelFieldName = formatterParams['level_field'];
        if (levelFieldName)
        {
            var levelLabels = formatterParams['level_performance_labels'];
            var levelKey = row.getCell(levelFieldName).getValue();
            var levelLabel = levelLabels[levelKey] ?? null;
            cell.getRow().getElement().classList.add('-performance-' + levelLabel);
            cell.getElement().classList.add('-performance-' + levelLabel);
        }

        var seFieldName = formatterParams['se_field'];
        if (seFieldName) {
            var seValue = row.getCell(seFieldName).getValue();
            if (seValue !== '0' && seValue !== 0) {
                text = text + '<span class="TablePercentBar__se">' + seValue + '</span></div>'
            }
        }
        else {
            cell.getElement().classList.add('-se-off');
            text = text + '</div>';
        }

        var cumulativePercent = 0;
        var cumulativePercentFieldName = formatterParams['cumulative_percent_field'];
        if (cumulativePercentFieldName) {
            cumulativePercent = row.getCell(cumulativePercentFieldName).getValue() * 1;
        }

        var widthPercent = cell.getValue();

        var bar = '<div class="TablePercentBar__bar"><div class="TablePercentBar__value" style="left: ' + cumulativePercent + '%; width:' + widthPercent + '%;"></div></div>';
        return '<div class="TablePercentBar__cell"><div class="TablePercentBar__vis">' + bar + text + '</div></div>';
    };

    var tabulatorProfScoreBarFormatter = function(cell, formatterParams, onRendered)
    {
        var row = cell.getRow();

        var text = '<div class="TableProfScoreBar__text"><span class="TableProfScoreBar__percent">' + cell.getValue() + '</span>';
        cell.getElement().classList.add('TableProfScoreBar')

        var seFieldName = formatterParams['se_field'];
        if (seFieldName) {
            var seValue = row.getCell(seFieldName).getValue();
            if (seValue !== '0' && seValue !== 0) {
                text = text + '<span class="TableProfScoreBar__se">' + seValue + '</span></div>';
            }
        }
        else {
            cell.getElement().classList.add('-se-off');
            text = text + '</div>';
        }

        var doShowBar = formatterParams['do_show_bar'];
        if (doShowBar)
        {
            var psData = null;
            var psFieldName = formatterParams['ps_field'];
            if (psFieldName) {
                var psKey = row.getCell(psFieldName).getValue().toLowerCase();
                if (psKey) {
                    psData = formatterParams['prof_scales'][psKey];
                }
            }

            if (psData)
            {
                var totalCount = psData['TotalCount'];
                var countAdjustment = psData['CountAdjustment'];
                var benchmarkPercent = 0;

                var cohortKey = formatterParams['cohort_key'];
                if (!cohortKey) {
                    var cohortFieldName = formatterParams['cohort_field'];
                    if (cohortFieldName) {
                        cohortKey = row.getCell(cohortFieldName).getValue();
                    }
                }
                if (cohortKey) {
                    benchmarkPercent = psData['Benchmarks'][cohortKey] ?? 0;
                }

                var score = cell.getValue();
                var adjScore = score - countAdjustment;
                var scorePercent = adjScore / totalCount * 100;

                var bar = '<div class="TableProfScoreBar__bar">';
                if (benchmarkPercent > 0) {
                    bar += '<span class="TableProfScoreBar__benchmark" style="left: ' + benchmarkPercent + '%;"></span>';
                }
                bar += '<div class="TableProfScoreBar__value" style="width:' + scorePercent  + '%;"></div>';
                bar += '</div>';
                return '<div class="TablePercentBar__cell"><div class="TablePercentBar__vis">' + bar + text + '</div></div>';
            }
        }
        return text;
    };

    var tabulatorDifferenceBarFormatter = function(cell, formatterParams, onRendered)
    {
        cell.getElement().classList.add('TableDifferenceBar');
        var row = cell.getRow();
        var currValue = cell.getValue();

        if (currValue === 0) {
            return '';
        }

        var isNegativeValue = currValue < 0;
        var absValue = Math.abs(currValue);
        var maxValue = formatterParams['max_value'];
        var percent = absValue / maxValue * 100 / 2;

        var bar = isNegativeValue
            ? '<div class="TableDifferenceBar__cell is-left"><div class="TablePercentBar__vis">'
            : '<div class="TableDifferenceBar__cell is-right"><div class="TablePercentBar__vis">';
        bar += '<div class="TableDifferenceBar__bar" style="width: ' + percent + '%;">';
        bar += '</div></div></div>';
        return bar;
    };

    var tabulatorActionHandler = function(e) {
        e.preventDefault();
        console.log('here');
        var action = e.target;
        if (!action.hasAttribute('data-datatable-action')) {
            action = getClosest(action, '.js-datatable-action');
        }
        console.log(action);
        var actionName = action.getAttribute('data-datatable-action');
        var datatableID = action.getAttribute('data-datatable-action-target');
        console.log(actionName);
        console.log(datatableID);
        console.log(window.activeTabulators);
        if (actionName === null || datatableID === null) return false;
        var tabulator = window.activeTabulators[datatableID];
        tabulatorActions[actionName].callback(tabulator);
    };

    var resetDatatableOverflowStateClasses = function(container)
    {
        var datatable = getClosest(container, '.js-datatable');
        var table = datatable.querySelector('.js-datatable-table .tabulator-table');

        var shadowStart = datatable.querySelector('.js-datatable-shadow-start');
        var shadowEnd = datatable.querySelector('.js-datatable-shadow-end');

        var boundLeft = container.getBoundingClientRect().left;
        var boundRight = container.getBoundingClientRect().right;
        var boundWidth = container.getBoundingClientRect().width;

        var tableLeft = table.getBoundingClientRect().left;
        var tableRight = table.getBoundingClientRect().right;
        var tableWidth = table.getBoundingClientRect().width;

        var scrollHelper = datatable.querySelector('.js-datatable-scrollerskates');
        if (tableWidth > boundWidth) {
            scrollHelper.style.width = tableWidth + 'px';
        } else {
            scrollHelper.style.width = 'auto';
        }

        if (tableLeft < boundLeft)
        {
            if (!(datatable.classList.contains('is-overflow-start'))) {
                datatable.classList.add('is-overflow-start');
            }

            var leftDistance = boundLeft - tableLeft;
            var leftHidden = leftDistance / tableWidth;
            if (leftHidden < 0.2) leftHidden = 0.2;
            shadowStart.style.opacity = leftHidden;
        }
        else {
            datatable.classList.remove('is-overflow-start');
            shadowStart.style.removeProperty('opacity');
        }

        if ((tableRight - 1) > boundRight)
        {
            if (!(datatable.classList.contains('is-overflow-end'))) {
                datatable.classList.add('is-overflow-end');
            }

            var rightDistance = tableRight - boundRight;
            var rightHidden = rightDistance / tableWidth;
            if (rightHidden < 0.2) rightHidden = 0.2;
            shadowEnd.style.opacity = rightHidden;
        }
        else {
            datatable.classList.remove('is-overflow-end');
            shadowEnd.style.removeProperty('opacity');
        }
    };

    var defaultTableOptions = {
        groupHeader: function(value, count, data, group){
            return value;
        }
    };

    var datatables = document.querySelectorAll('.js-datatable');
    Array.prototype.forEach.call(datatables, function(datatable, i)
    {
        var datatableID = datatable.getAttribute('data-datatable-id');
        var tableOptionsJSON = datatable.getAttribute('data-datatable-options');
        var table = datatable.querySelector('.js-datatable-table');

        if (datatableID === null) return false;
        if (table === null) return false;

        try {
            var tableOptions = JSON.parse(tableOptionsJSON);
        } catch (e) {
            return false;
        }

        tableOptions = mergeObjects(true, defaultTableOptions, tableOptions);
        Array.prototype.forEach.call(tableOptions.columns, function(tableColumn, i) {
            if (tableColumn.formatter === 'percentbar') {
                tableColumn.formatter = tabulatorPercentBarFormatter;
            }
            if (tableColumn.formatter === 'profscorebar') {
                tableColumn.formatter = tabulatorProfScoreBarFormatter;
            }
            if (tableColumn.formatter === 'differencebar') {
                tableColumn.formatter = tabulatorDifferenceBarFormatter;
            }
            if (tableColumn.formatter === 'distributionbar') {
                tableColumn.formatter = tabulatorDistributionBarFormatter;
            }
        });

        var tabulator = new Tabulator(table, tableOptions);
        window.activeTabulators[datatableID] = tabulator;

        tabulator.on('tableBuilt', function() {
            var table = tabulator.element;
            var datatable = getClosest(table, '.js-datatable');

            if (window.isPDFExport) {
                return;
            }

            var container = datatable.querySelector('.js-datatable-container');
            var simpleBar = new SimpleBar(container, {autoHide: false});
            simpleBar.getScrollElement().addEventListener('scroll', function(e) {
                resetDatatableOverflowStateClasses(e.target);
            });

            resetDatatableOverflowStateClasses(container);

            var resizeObserver = new ResizeObserver(function(entries) {
                Array.prototype.forEach.call(entries, function(entry, i) {
                    resetDatatableOverflowStateClasses(entry.target);
                });
            });
            resizeObserver.observe(container);
        });
    });

    var datatableActions = document.querySelectorAll('.js-datatable-action');
    Array.prototype.forEach.call(datatableActions, function(datatableAction, i) {
        onClickOrTap(datatableAction, tabulatorActionHandler);
    });

}());
