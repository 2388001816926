window.activeA11yDialogsMap = new Map();
var doInitA11yDialog = function(element)
{
    var dialog = new A11yDialog(element);
    dialog
        .on('show', function() {
            bodyScrollLock.disableBodyScroll(
                element,
                {
                    allowTouchMove: function(el) {
                        while (el && el !== document.body) {
                            if (el.getAttribute('data-body-scroll-lock-ignore') !== null) {
                                return true;
                            }
                            el = el.parentElement;
                        }
                    }
                }
            );
        })
        .on('hide', function() {
            bodyScrollLock.enableBodyScroll(element);
            var toggleGroups = element.querySelectorAll('.js-toggle-group');
            Array.prototype.forEach.call(toggleGroups, function(toggleGroup, i) {
                doCloseToggleGroup(toggleGroup);
            });
        });
    window.activeA11yDialogsMap.set(element.id, dialog);
};

var getActiveA11yDialog = function(elementID)
{
    return window.activeA11yDialogsMap.get(elementID);
};

;(function () {
    'use strict';
    ready(function() {
        var elements = document.querySelectorAll('.js-modal');
        Array.prototype.forEach.call(elements, function(element, i) {
            doInitA11yDialog(element);
        });
    });
}());
