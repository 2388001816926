;(function () {
    'use strict';

    if (typeof(Storage) !== "undefined" && sessionStorage.getItem("fontsLoaded") === "true") {
        var htmlEl = document.documentElement;
        if (!htmlEl.classList.contains("fonts-loaded")) {
            htmlEl.classList.add("fonts-loaded");
        }
    }
    else {
            var PTSans400 = new FontFaceObserver("PT Sans", {weight: 400, style: "normal"});
            var PTSans400i = new FontFaceObserver("PT Sans", {weight: 400, style: "italic"});
            var PTSans700 = new FontFaceObserver("PT Sans", {weight: 700, style: "normal"});
            var PTSans700i = new FontFaceObserver("PT Sans", {weight: 700, style: "italic"});

            Promise.all([
                PTSans400.load(),
                PTSans400i.load(),
                PTSans700.load(),
                PTSans700i.load()
            ])
                .then(function() {
                    document.documentElement.classList.add("fonts-loaded");
                    if (typeof(Storage) !== "undefined") {
                        sessionStorage.setItem("fontsLoaded", "true");
                    }
                })
                .catch(function (err) {
                    document.documentElement.classList.remove("fonts-loaded");
                    if (typeof(Storage) !== "undefined") {
                        sessionStorage.setItem("fontsLoaded", "false");
                    }
                    console.warn("fontcheck: Some fonts did not load:", err);
                });
    }

}());
