;(function () {
    'use strict';

    window.activeChartists = [];

    var defaultChartistOptions = {};

    var chartists = document.querySelectorAll('.js-chartist[data-chartist]');
    Array.prototype.forEach.call(chartists, function(myChartist, i)
    {
        var chartID = myChartist.getAttribute('data-chartist');
        var chartOptionsJSON = myChartist.getAttribute('data-chartist-options');
        var chart = myChartist.querySelector('.js-chartist-chart');
        if (typeof chartID !== 'undefined' && chartID !== null) {
            try {
                var chartOptions = JSON.parse(chartOptionsJSON);
            } catch (e) {
                return false;
            }
            chartOptions = mergeObjects(true, defaultChartistOptions, chartOptions);
            new Chartist.LineChart('.js-chartist-chart', chartOptions.data, chartOptions.style);
        }
    });
}());
