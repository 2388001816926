;(function () {
    'use strict';

    window.activeHighcharts = [];

    var highchartActions = {
        fullscreen: {
            callback: function(highchart) {
                highchart.fullscreen.toggle();
            }
        },
        png: {
            callback: function(highchart) {
                highchart.exportChartLocal({type: 'image/png'});
            }
        },
        jpg: {
            callback: function(highchart) {
                highchart.exportChartLocal({type: 'image/jpeg'});
            }
        },
        svg: {
            callback: function(highchart) {
                highchart.exportChartLocal({type: 'image/svg+xml'});
            }
        },
        csv: {
            callback: function(highchart) {
                highchart.downloadCSV();
            }
        },
        xls: {
            callback: function(highchart) {
                highchart.downloadXLS();
            }
        }
    };

    var highchartActionHandler = function(e) {
        e.preventDefault();
        var action = e.target;
        var actionID = action.getAttribute('data-highchart-action');
        if (typeof actionID !== 'undefined' && actionID !== null) {
            var chart = getClosest(action, '.js-highchart[data-highchart]');
            if (typeof chart !== 'undefined' && chart !== null) {
                var chartID = chart.getAttribute('data-highchart');
                var highchart = window.activeHighcharts[chartID];
                highchartActions[actionID].callback(highchart);
            }
        }
    };

    var doAnimate = !window.isPDFExport;

    Highcharts.setOptions({
        // lang: {
        //     thousandsSep: ",",
        //     numericSymbols: null
        // }
    });

    var highcharts = document.querySelectorAll('.js-highchart[data-highchart]');
    Array.prototype.forEach.call(highcharts, function(highchart, i)
    {
        var chartID = highchart.getAttribute('data-highchart');
        var chartOptionsJSON = highchart.getAttribute('data-highchart-options');
        var chart = highchart.querySelector('.js-highchart-chart');
        if (typeof chartID !== 'undefined' && chartID !== null) {
            try {
                var chartOptions = JSON.parse(chartOptionsJSON);
                // console.log(chartOptions);
                // var chartOptions = window.highchartsData[chartID];

                var allChartSeries = chartOptions.series;
                Array.prototype.forEach.call(allChartSeries, function(chartSeries, i) {
                    if (chartSeries.doAttachToLegend) {
                        chartSeries.events.legendItemClick = function(e) {return false;};
                    }
                });
            } catch (e) {
                return false;
            }
            if (chartOptions) {
                window.activeHighcharts[chartID] = new Highcharts.chart(chart, chartOptions);
            }
        }

        var actions = highchart.querySelectorAll('.js-highchart-action[data-highchart-action]');
        Array.prototype.forEach.call(actions, function(action, i) {
            onClickOrTap(action, highchartActionHandler);
        });
    });
}());
